import React from "react"
import Helmet from "react-helmet"
import SEO from "../../components/seo"

function FormPage () {

return (
<>
<SEO title="Circles to Feeding Self-Assessment"/> 
<div className="pt-6">
    <img className="mx-auto" width="100px" alt="" src="https://res.cloudinary.com/mysnapshot/image/upload/v1714299247/Course%20Logos/Babble_Logo_vkixwo.png"/>
</div>
<div className="-mt-8" id="ff-compose"></div>
<Helmet>
    <style>{`
        #Publish1FAIpQLSdY5Iif1xjJEluSL7sf6w_YmFtE3uY1PZRhWvDtPh7du00bgg {
            padding: 30px !important;
            padding-bottom: 100px !important;
 }
        .ff-compose {
            min-height: calc(100vh - 30px);
        }

        #___gatsby {
            background-color: #FFFFFF !important;
        }

        .ff-partial {
            margin-top: 2.2em; 
        }
        
        #ff-sec-root {
            padding-top: 2em !important;
            min-height: 100%;
            
        }
        
/* Removed Exclamation Mark in Warning Message. */
.ff-widget-error b {
    display: none !important;
}
/* END Removed Exclamation Mark in Warning Message. */

/* Submit page style */
.ff-button-bar button {
    height: 44px !important;
}
.rest-btn.ff-back {
    background: transparent !important;
    border: 1px solid #e1d7d7 !important;
}
/* END Submit page style */

/* Warning message text style. */
.ff-widget-error {
    color: #B52632 !important;
    font-weight: 400 !important;
}
/* END Warning message text style. */

/*  Style for You partially filled this form */
.ff-partial.ff-message {
    background: #eeeeee;
    color: black;
}
.ff-partial.ff-message a {
    color: black !important;
}
/* END Style for You partially filled this form */

/* Black asterisk  */
span.ff-required {
    color: black !important;
}
/* END Black asterisk */

/* Tutor image remove padding */ 
.ff-layout-1column .ff-secfields {
    padding-bottom: 0;
    }
/* END Tutor image remove padding */ 

        `}
    </style>
        <script async defer src="https://formfacade.com/include/105416567367288830990/form/1FAIpQLSdY5Iif1xjJEluSL7sf6w_YmFtE3uY1PZRhWvDtPh7du00bgg/classic.js?div=ff-compose">
        </script></Helmet>


</>
)
} 

export default FormPage
